<script>
export default {
  name: 'CprHeader',
}
</script>

<template>
  <div class="cpr">
    <router-link to="/test">
      <div class="logo_wrap">
        <img src="@/assets/img/common/lexcode_logo.png" />
      </div>
      <h1>테스트 게시판</h1>
    </router-link>
  </div>
</template>